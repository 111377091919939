<template>
  <div class="client-dashboard">
    <PaymentMethodAlert />
    <Summary />
    <b-row>
      <b-col>
        <daily-sales-graph />
      </b-col>
    </b-row>

    <b-row align-h="between">
      <b-col cols="12" md="6">
        <sales-performance />
      </b-col>
      <b-col cols="12" md="6">
        <royalties-chart />
      </b-col>
    </b-row>
    <b-row align-h="between">
      <b-col cols="12" md="7">
        <sales-by-territory />
      </b-col>
      <b-col cols="12" md="5">
        <all-time-best-sellers />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from "vuex";

import SalesPerformance from "@/views/client/reports/sales-performance";
import SalesByTerritory from "@/views/client/reports/sales-by-territory";
import AllTimeBestSellers from "@/views/client/reports/all-time-best-sellers";
import RoyaltiesChart from "@/views/client/reports/royalties-chart";
import Summary from "./dashboard/Summary.vue";
import PaymentMethodAlert from "../../components/PaymentMethodAlert";
import DailySalesGraph from "@/views/client/reports/daily-sales-graph";

export default {
  name: "admin",
  components: {
    DailySalesGraph,
    Summary,
    AllTimeBestSellers,
    SalesByTerritory,
    SalesPerformance,
    RoyaltiesChart,
    PaymentMethodAlert
  },
  computed: {
    ...mapState("auth", ["user"])
  }
};
</script>
