<template>
  <div
    class="payment-method-alert"
    v-if="paymentPreferences && !paymentPreferences.payment_method_id"
  >
    <b-alert show variant="danger">
      <div class="block d-md-flex align-items-center">
        <p class="mb-0">
          Reminder: Please complete your royalty payment preferences.
        </p>
        <ebp-button
          class="ml-md-2 mt-md-0 mt-3"
          size="sm"
          :to="{
            name: 'MyProfile',
            query: { tab: 'payment-preferences' }
          }"
          >{{ $t("add-method") }}</ebp-button
        >
      </div>
    </b-alert>
  </div>
</template>

<script>
import { mapKeys } from "lodash-es";

export default {
  name: "payment-method-alert",
  mounted() {
    this.getPaymentPreferences();
  },
  data() {
    return {
      paymentPreferences: null
    };
  },
  methods: {
    async getPaymentPreferences() {
      try {
        const res = await this.$store.dispatch("users/getPaymentPreferences");

        const { payment_method_details } = res.data;

        this.paymentPreferences = {
          ...this.paymentPreferences,
          ...res.data,
          ...mapKeys(payment_method_details, (_, k) => "payment_details." + k)
        };
      } catch (err) {
        console.error(err);
      }
    }
  }
};
</script>

<style></style>
