<template>
  <div class="dashboard-summary">
    <b-row align-h="between">
      <b-col cols="12" sm="6" lg="3">
        <CountCard
          icon="bx-cart"
          :count="stats.current_year_sales"
          :title="$t('sales-download', { year })"
        />
      </b-col>
      <b-col cols="12" sm="6" lg="3">
        <CountCard
          icon="bx-money"
          :count="stats.current_year_royalties"
          :title="$t('royalties-year', { year })"
          :format="amount => formatCurrency(amount, currencyCode)"
        />
      </b-col>
      <b-col cols="12" sm="6" lg="3">
        <CountCard
          icon="bxs-bank"
          :count="stats.next_payment_value"
          :title="$t('next-payment')"
          :format="amount => formatCurrency(amount, currencyCode)"
        />
      </b-col>
      <b-col cols="12" sm="6" lg="3">
        <CountCard
          icon="bx-book"
          :count="stats.live_books"
          :title="$t('live-books')"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import CountCard from "@/components/core/count-card";

export default {
  name: "admin",
  components: {
    CountCard
  },
  data() {
    return {
      stats: {
        current_year_sales: 0,
        current_year_royalties: 0,
        next_payment_value: 0,
        live_books: 0
      },
      currencyCode: "GBP",
      year: new Date().getFullYear()
    };
  },
  mounted() {
    this.get();
  },
  methods: {
    async get() {
      this.loading = true;

      try {
        const res = await this.$store.dispatch(
          "reports/getHomeSummary",
          this.year
        );
        this.stats = res.data;
        this.currencyCode = res.data.currency.code;
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    }
  }
};
</script>
