<template>
  <div class="sales-by-territory">
    <ebp-card :loading="loading">
      <b-row align-v="center">
        <b-col cols="12" md="9">
          <h5 class="ml-2 mb-3">All-time Sales by Territory</h5>
          <GChart
            type="GeoChart"
            :data="dataSource"
            :settings="{ packages: ['geochart'] }"
          />
        </b-col>
        <b-col cols="12" md="3">
          <div class="country-list">
            <div class="country" v-for="(item, i) in listItems" :key="i">
              <span class="name">{{ item.territory.name }}</span>
              <span class="sales">{{ formatNumber(item.total_sales) }}</span>
            </div>
          </div>

          <router-link :to="{ name: 'SalesByTerritory' }">
            <ebp-button class="mt-5">See full report </ebp-button>
          </router-link>
        </b-col>
      </b-row>
    </ebp-card>
  </div>
</template>

<script>
import { GChart } from "vue-google-charts";

export default {
  name: "sales-by-territory",
  components: {
    GChart
  },
  data() {
    return {
      render: false,
      listItems: [],
      loading: false,
      dataSource: []
    };
  },
  mounted() {
    this.get();
  },
  methods: {
    async get() {
      this.loading = true;

      try {
        const res = await this.$store.dispatch("reports/salesByTerritory");
        this.listItems = res.data
          .sort((a, b) => parseFloat(b.total_sales) - parseFloat(a.total_sales))
          .slice(0, 6);

        const data = res.data.map(({ territory, total_sales }) => [
          territory.name,
          total_sales
        ]);
        this.dataSource = [[this.$t("country"), this.$t("sales")], ...data];
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    }
  }
};
</script>

<style lang="scss">
.sales-by-territory {
  .country-list {
    display: flex;
    width: 100%;
    flex-direction: column;

    .country {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5rem;

      span {
        font-size: 0.87rem;
      }

      span.sales {
        font-weight: 500;
      }
    }
  }
}
</style>
