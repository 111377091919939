<template>
  <div class="all-time-best-sellers">
    <ebp-card :loading="loading">
      <h5 class="ml-2 mb-3">{{ $t("all-time-best-sellers") }}</h5>
      <b-table :fields="headers" :items="books">
        <template #cell(title)="{item}">
          <span
            v-tooltip.top-center="
              item.book_type === 'print' ? item.title + ' (print)' : item.title
            "
            >{{
              truncate(
                item.book_type === "print"
                  ? item.title + " (print)"
                  : item.title,
                40
              )
            }}</span
          >
        </template>
        <template #cell(publication_date)="{item}">
          {{ item.publication_date ? formatDate(item.publication_date) : "" }}
        </template>
        <template #cell(sales_count)="{item}">
          {{ formatNumber(item.total_sales_count) }}
        </template>
      </b-table>
      <router-link :to="{ name: 'SalesByTitle' }">
        <ebp-button class="center-block">See full report </ebp-button>
      </router-link>
    </ebp-card>
  </div>
</template>

<script>
import truncate from "@/helpers/truncate";

export default {
  name: "all-time-best-sellers",
  data() {
    return {
      loading: false,
      books: new Array(5).fill({
        title: "",
        total_sales_count: 0,
        created_at: ""
      }),
      headers: [
        {
          key: "title",
          label: "book-title"
        },
        {
          key: "sales_count",
          label: "sales"
        },
        {
          key: "publication_date",
          label: "published-on"
        }
      ].map(i => ({
        ...i,
        label: this.$t(i.label)
      }))
    };
  },
  mounted() {
    this.get();
  },
  methods: {
    truncate,
    async get() {
      this.loading = true;

      try {
        const res = await this.$store.dispatch("books/get", {
          limit: 5,
          sort_by: "total_sales_count",
          sort_order: true
        });
        this.books = res.data.data;
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    }
  }
};
</script>
