<template>
  <div class="sales-performance">
    <ebp-card :loading="loading">
      <h5 class="ml-2 mb-3">{{ $t("sales-performance") }}</h5>
      <apexchart
        width="100%"
        type="area"
        :series="series"
        :options="chartOptions"
        height="300px"
      />
      <router-link :to="{ name: 'SalesBySalesChannel' }">
        <ebp-button class="center-block">See full report </ebp-button>
      </router-link>
    </ebp-card>
  </div>
</template>

<script>
import { add, format, sub } from "date-fns";
import { find } from "lodash-es";

const oneYearAgo = sub(new Date(), { months: 11 });
const twelveElementsEmptyArray = new Array(11).fill(0);

export default {
  name: "sales-performance",
  data() {
    return {
      loading: false,
      chartOptions: {
        colors: this.$colors,
        chart: {
          fontFamily: "SF UI Text",
          toolbar: false
        },
        xaxis: {
          categories: twelveElementsEmptyArray
            .map((_, i) => format(add(oneYearAgo, { months: i }), "MMM-yy"))
            .map(m => this.$t(m))
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 2,
          curve: "smooth"
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6
          },
          style: "hollow"
        },
        tooltip: {
          y: [
            {
              title: {
                formatter: function(val) {
                  return val;
                }
              }
            }
          ]
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.6,
            opacityTo: 0.05,
            stops: [42, 100, 100, 100]
          }
        }
      },
      series: []
    };
  },
  mounted() {
    this.get();
  },
  methods: {
    async get() {
      this.loading = true;

      try {
        const res = await this.$store.dispatch(
          "reports/getHomeSalesByCategory"
        );
        this.series = [
          {
            name: this.$t("retailers"),
            data: Object.values(this.addEmptyMonths(res.data.retailer || {}))
          },
          {
            name: this.$t("subscription-services"),
            data: Object.values(
              this.addEmptyMonths(res.data["subscription-services"] || {})
            )
          },
          {
            name: this.$t("print"),
            data: Object.values(this.addEmptyMonths(res.data.print || {}))
          }
        ];
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    },
    addEmptyMonths(rows) {
      const records = {};
      const dateStart = sub(new Date(), { months: 11 });
      twelveElementsEmptyArray.forEach((_, i) => {
        const key = format(
          add(dateStart, {
            months: i
          }),
          "yyyyMM"
        );
        const monthExists = find(rows, o => o.period_label === key);
        records[key] = monthExists ? monthExists.total_sales : 0;
      });

      return records;
    }
  }
};
</script>
