<template>
  <div class="royalties-chart">
    <ebp-card :loading="loading">
      <h5 class="ml-2 mb-3">{{ $t("royalties-generated") }}</h5>
      <apexchart
        type="bar"
        height="300px"
        :options="chartOptions"
        :series="series"
      />
      <router-link :to="{ name: 'RoyaltyReport' }">
        <ebp-button class="center-block">See full report </ebp-button>
      </router-link>
    </ebp-card>
  </div>
</template>

<script>
const oneYearAgo = sub(new Date(), { months: 11 });
const twelveElementsEmptyArray = new Array(11).fill(0);
import { add, format, sub } from "date-fns";
import { find } from "lodash-es";

export default {
  name: "royalties-chart",
  data() {
    return {
      loading: false,
      currency: {},
      chartOptions: {
        colors: this.$colors,
        legend: {
          show: true,
          position: "bottom",
          offsetY: 40
        },
        chart: {
          height: 350,
          type: "bar",
          toolbar: false,
          fontFamily: "SF UI Text"
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: "top",
              orientation: "horizontal"
            }
          }
        },
        dataLabels: {
          enabled: true,
          formatter: val => {
            return this.formatCurrency(val, this.currency.code);
          },
          offsetY: -25,
          style: {
            fontSize: "10px",
            colors: ["#333"]
          }
        },

        xaxis: {
          categories: twelveElementsEmptyArray
            .map((_, i) => format(add(oneYearAgo, { months: i }), "MMM-yy"))
            .map(m => this.$t(m)),
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#2561a1",
                colorTo: "#2561a1",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5
              }
            }
          },
          tooltip: {
            enabled: true
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            show: false,
            formatter: val => {
              return this.formatCurrency(val, this.currency.code);
            }
          }
        }
      },
      series: [
        {
          name: this.$t("total-royalties-$"),
          data: []
        }
      ]
    };
  },
  mounted() {
    this.get();
  },
  methods: {
    async get() {
      this.loading = true;

      try {
        const res = await this.$store.dispatch("reports/getRoyaltiesPerMonth");
        this.series = [
          {
            name: this.$t("total-royalties-$"),
            data: Object.values(this.addEmptyMonths(res.data))
          }
        ];
        this.currency = res.data[0] ? res.data[0].currency : {};
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    },
    addEmptyMonths(rows) {
      const records = {};
      const dateStart = sub(new Date(), { months: 11 });
      twelveElementsEmptyArray.forEach((_, i) => {
        const key = format(
          add(dateStart, {
            months: i
          }),
          "yyyyMM"
        );
        const monthExists = find(rows, o => o.period_label === key);
        records[key] = monthExists ? monthExists.total_royalties : 0;
      });

      return records;
    }
  }
};
</script>
